import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";
import { createApp } from 'vue'
import VueSweetalert2 from "vue-sweetalert2";
import AOS from "aos";
import * as VueGoogleMaps from "vue2-google-maps";
import "firebase/auth";
import 'vuetify/dist/vuetify.min.css'



//Kanban
import vueKanban from "vue-kanban";
Vue.use(vueKanban);

//Editor
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import "aos/dist/aos.css";
import { VBHover } from "bootstrap-vue";

Vue.use(VBHover);

AOS.init({
  disable: "mobile",
});


// QR codes
import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);

//google
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDFI9tO_rsPTsbP4Rp2KlNUrInl64Ruy-I",
    libraries: "places",
  },
  installComponents: true,
});



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBkt6wrSrXvSmgT_FlfikEY8B89YEiR0UM",
  authDomain: "flexvastgoedcrm.firebaseapp.com",
  projectId: "flexvastgoedcrm",
  storageBucket: "flexvastgoedcrm.appspot.com",
  messagingSenderId: "610381213302",
  appId: "1:610381213302:web:29eef376bd94a581d93957",
  measurementId: "G-HYFRX62TG6"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.use(VueSweetalert2);

//images
import VueLazyload from "vue-lazyload";
import { nb } from "date-fns/locale";
Vue.use(VueLazyload);


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCijS4fTfbTjNRQ- 3Typgi7HEOl1kYKWII',
    libraries: 'places',
  },
  installComponents: true
})

Vue.config.productionTip = false;

// eslint-disable-next-line no-unused-vars
let app;

firebase.auth().onAuthStateChanged(async function () {
  const user = firebase.auth().currentUser;
  if (user && router.currentRoute.path === '/') {
    router.push('/Dashboard');
  }

  new Vue({
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
  app = true;
});




