<template>
  <v-app>
    <div class="main-content" id="mainContent">
      <transition name="fade" mode="out-in">
        <router-view ref="router" />
      </transition>
    </div>
    

   </v-app>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      deferredPrompt: null,
    };
  },


};
</script>

<style>

.pac-container {
  z-index: 2000 !important;
}
</style>

<style scoped lang="scss">
.pwa-banner {
  height: 10vh;
  z-index: 1000;
  padding: 1rem;
  display: flex;
  justify-content: space-between;

  .banner-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  height: 100%;
}
// .main-content {
//   margin: 10px 0px;
// }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
