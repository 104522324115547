import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";
import "firebase/auth";
Vue.use(VueRouter);
const isLoggedIn = () => firebase.auth().currentUser;

const routes = [
  {
    path: "/",
    name: "/",
    component: () =>
      import(
        /* webpackChunkName: "Home" */ "../views/app/login/LoginScreen.vue"
      ),
    meta: {
      title: " Flex vastgoed CRM | Vastgoed",
    },
  },

  {
    path: "/ReparatieBewijs/submission/:code/:id",
    name: "ReparatieBewijs",
    component: () => import("@/views/app/repair/ReparatieBewijs.vue"),
    meta: {
      showFooter: false,
      title: "Flex CRM Vastgoed | Inbox",
    },
  },


  {
    path: "/Dashboard",
    name: "DashboardLayout",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/layouts/AdminLayout.vue"),
    meta: { requiresAuth: true, accessRights: ["WERKNEMER"] },
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "@/views/app/dashboard/Dashboard.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " Flex vastgoed CRM | Dashboard",
        },
      },

      {
        path: "/Profile",
        name: "Profile",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/profile/Profile.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " Flex vastgoed CRM  | Password",
        },
      },

      {
        path: "/Password",
        name: "Password",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/profile/Password.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " Flex vastgoed CRM  | Password",
        },
      },
      //Objecten
      {
        path: "/Objecten",
        name: "Objecten",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/vastgoed/Objecten.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " Flex vastgoed CRM | Alle objecten",
        },
      },

      {
        path: "/ObjectInfo/:id",
        name: "ObjectInfo",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/vastgoed/ObjectInfo.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Object informatie ",
        },
      },

      //Plug-ins

      {
        path: "/Plugins",
        name: "Plugins",
        component: () =>
          import(
            /* webpackChunkName: "ObjectToevoegen" */ "@/views/app/modules/Plugins.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " Vastoed  | Modules",
        },
      },

      //notes
      {
        path: "/Lead",
        name: "Lead",
        component: () =>
          import(
            /* webpackChunkName: "ObjectToevoegen" */ "@/views/app/lead/Lead.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Notities ",
        },
      },

      {
        path: "/LeadDetails/:id",
        name: "LeadDetails",
        component: () =>
          import(
            /* webpackChunkName: "ObjectToevoegen" */ "@/views/app/lead/LeadDetails.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Notities ",
        },
      },

      {
        path: "/Scrumboard",
        name: "Scrumboard",
        component: () =>
          import(
            /* webpackChunkName: "ObjectToevoegen" */ "@/views/app/kanban/Scrumboard.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Scrumboard ",
        },
      },

      {
        path: "/ScrumStatus",
        name: "ScrumStatus",
        component: () =>
          import(
            /* webpackChunkName: "ObjectToevoegen" */ "@/views/app/kanban/ScrumStatus.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Scrumboard ",
        },
      },
      {
        path: "/kanban/details/:id",
        name: "ScrumDetails",
        component: () =>
          import(
            /* webpackChunkName: "ObjectToevoegen" */ "@/views/app/kanban/ScrumDetails.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Scrumboard ",
        },
      },
      // Settings

      // Settings

      {
        path: "/Systeem",
        name: "Systeem",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/Instellingen/Systeem.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Systeem ",
        },
      },

      {
        path: "/Rechten",
        name: "Rechten",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/Instellingen/Rechten.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Rechten ",
        },
      },

      {
        path: "/Import",
        name: "Import",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/tools/Import.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Import ",
        },
      },

      {
        path: "/ActiviteitenLogs",
        name: "ActiviteitenLogs",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/Instellingen/ActiviteitenLogs.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | ActiviteitenLogs ",
        },
      },

      {
        path: "/Email-Import",
        name: "Email-Import",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/Instellingen/Email-Import.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Mail-import ",
        },
      },

      {
        path: "/SMS-Module",
        name: "SMS-Module",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/Instellingen/SMS-Module.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | SMS-module",
        },
      },
      //Contract
      {
        path: "/Contracten",
        name: "Contracten",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/contract/Contracten.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Contracten",
        },
      },

      {
        path: "/ContractInformatie/:id",
        name: "ContractInformatie",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/contract/ContractInformatie.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Contracten",
        },
      },

      {
        path: "/ContractTemplates",
        name: "ContractTemplates",
        component: () =>
          import(
            /* webpackChunkName: "contracttemplates" */ "@/views/app/contract/ContractTemplates.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Contract templates",
        },
      },

      {
        path: "/ContractRapport",
        name: "ContractRapport",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/contract/ContractRapport.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Contract Rapport",
        },
      },

      {
        path: "/ContractType",
        name: "ContractType",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/contract/ContractType.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Contract Rapport",
        },
      },

      //Instellingen

      {
        path: "/GDPR",
        name: "GDPR",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/Instellingen/GDPR.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | GDPR ",
        },
      },

      {
        path: "/Folders",
        name: "Folders",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/Instellingen/Folders.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Folders ",
        },
      },

      {
        path: "/ApiDoc",
        name: "ApiDoc",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/Instellingen/ApiDoc.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | ApiDoc ",
        },
      },

      {
        path: "/MailServer",
        name: "MailServer",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/Instellingen/MailServer.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Mailserver ",
        },
      },

      {
        path: "/Categories",
        name: "Categories",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/vastgoed/Categories.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Categories ",
        },
      },

      {
        path: "/Facilitie",
        name: "Facilitie",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/vastgoed/Facilitie.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Faciliteit ",
        },
      },
      {
        path: "/Projecten",
        name: "Projecten",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/project/Projecten.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Projecten ",
        },
      },
      {
        path: "/Project/informatie/:id",
        name: "ProjectDetails",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/project/ProjectDetails.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Projecten ",
        },
      },

      {
        path: "/Sleutelbeheer",
        name: "Sleutelbeheer",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/vastgoed/Sleutelbeheer.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,

          title: " FLex CRM Vastgoed | Sleutels ",
        },
      },
      //Personeelszaken
      {
        path: "/Medewerkersbeheren",
        name: "Medewerkersbeheren",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/employee/Medewerkersbeheren.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Medewerkersbeheren ",
        },
      },

      //Kennisbank
      {
        path: "/KnowledgeBase",
        name: "KnowledgeBase",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/knowledgebase/KnowledgeBase.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | KnowledgeBaseItems ",
        },
      },

      {
        path: "/KnowledgeCategorie",
        name: "KnowledgeCategorie",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/knowledgebase/KnowledgeCategorie.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Knowledge Categorie ",
        },
      },

      {
        path: "/KnowledgeBase/:id",
        name: "KnowledgeBase",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/knowledgebase/SingleKnowledgeItem.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | KnowledgeBaseItems ",
        },
      },

      {
        path: "/Monteursbeheren",
        name: "Monteursbeheren",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/relaties/Monteursbeheren.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Monteursbeheren ",
        },
      },

      {
        path: "/Monteursbeheren/:id",
        name: "Monteurinformatie",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/relaties/SingleMonteursbeheren.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Monteursbeheren ",
        },
      },

      {
        path: "/Relaties",
        name: "Relaties",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/relaties/Relaties.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Relaties ",
        },
      },

      {
        path: "/Relatie/:id",
        name: "Relatie",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/relaties/Relatie.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Monteursbeheren ",
        },
      },

      {
        path: "/Huurders",
        name: "Huurders",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/relaties/tenant/Huurders.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Huurders ",
        },
      },

      {
        path: "/HuurderProfiel/:id",
        name: "HuurderProfiel",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/relaties/tenant/HuurderProfiel.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Huurders ",
        },
      },

      //Bedrijfsprofiel
      {
        path: "/BedrijfsProfiel",
        name: "Bedrijfsprofiel",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/company/BedrijfsProfiel.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Inbox ",
        },
      },

      //Repairs
      {
        path: "/ReparatieMelden",
        name: "ReparatieMelden",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/repair/ReparatieMelden.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Inbox ",
        },
      },

    
      {
        path: "/Planboard",
        name: "Planboard",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/repair/Planboard.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Planboard ",
        },
      },

      {
        path: "/ReparatieRapport",
        name: "ReparatieRapport",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/repair/ReparatieRapport.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Inbox ",
        },
      },

      {
        path: "/Reparaties",
        name: "Reparaties",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/repair/Reparaties.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Inbox ",
        },
      },
      {
        path: "/SingleReparatie/:id",
        name: "SingleReparatie",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/repair/SingleReparatie.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Inbox ",
        },
      },
      //Meldingen
      {
        path: "/Meldingen",
        name: "Meldingen",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/tickets/Meldingen.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Inbox ",
        },
      },
      {
        path: "/MeldingStatus",
        name: "MeldingStatus",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/tickets/MeldingStatus.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Melding Status ",
        },
      },
      {
        path: "/MeldingRapport",
        name: "MeldingRapport",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/tickets/MeldingRapport.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Melding Rapport ",
        },
      },

      {
        path: "/MeldingBekijken/:id",
        name: "MeldingBekijken",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/tickets/MeldingBekijken.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Melding Bekijken ",
        },
      },

      {
        path: "/MeldingAanmaken",
        name: "Meldingaanmaken",
        component: () =>
          import(
            /* webpackChunkName: "Profile" */ "@/views/app/tickets/MeldingAanmaken.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Melding Aanmaken ",
        },
      },
//Mailtemplates
      {
        path: "/MailTemplateDetail/:id",
        name: "MailTemplateDetail",
        component: () =>
          import(
            /* webpackChunkName: "ObjectToevoegen" */ "@/views/app/mailtemplate/MailTemplateDetail.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Notities ",
        },
      },

      {
        path: "/Mailtemplates",
        name: "Mailtemplates",
        component: () =>
          import(
            /* webpackChunkName: "ObjectToevoegen" */ "@/views/app/mailtemplate/MailTemplates.vue"
          ),
        meta: {
          requiresAuth: true,
          accessRights: ["WERKNEMER"],
          showFooter: false,
          title: " FLex CRM Vastgoed | Notities ",
        },
      },


    ],
  },

  {
    path: "*",
    name: "paginanietgevonden",
    component: () =>
      import(
        /* webpackChunkName: "paginanietgevonden" */ "@/views/paginanietgevonden.vue"
      ),
    meta: { title: " Flex vastgoed CRM | Oeps pagina niet gevonden" },
  },
];

async function onAuthRequired(to, from, next) {
  document.title = to.meta.title || "Realestate Dashboard";

  if (to.meta.requiresAuth) {
    if (!isLoggedIn()) {
      return next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else if (to.path === "/") {
      return next({
        path: "/Dashboard",
      });
    }
  }
  next();
}

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(onAuthRequired);
export default router;